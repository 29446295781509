export default class IntegrationLogs {
    constructor(container, data) {
        this.container = container;
        this.data = data;

        this.createTabs();
        this.initTabEvents(); // Initialize events after tabs are created
    }

    createTabs() {
        const tabContainer = document.createElement('div');
        tabContainer.className = 'flex space-x-4 mb-4';

        // flip data
        this.data = Object.fromEntries(Object.entries(this.data).reverse());

        for (const [key, value] of Object.entries(this.data)) {
            const latestEntry = value[0]; // Assuming the most recent entry is the first in the array

            // Create tab button
            const tabButton = document.createElement('button');
            tabButton.className = 'tab-button flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded text-gray-800';
            tabButton.dataset.tab = `${key}-content`;

            // Set inner content with icon and text
            tabButton.innerHTML = `
                <svg class="w-5 h-5 ${latestEntry.status === 'success' ? 'text-green-500' : 'text-red-500'} mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    ${latestEntry.status === 'success'
                    ? '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>'
                    : '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>'}
                </svg>
                <span>${key.toUpperCase()} - ${latestEntry.status.toUpperCase()}</span>
                <span class="ml-auto text-gray-500 text-sm">${latestEntry.createdAt}</span>
            `;

            // Adding the event listener directly to the button
            tabButton.addEventListener('click', () => this.handleTabClick(tabButton));

            tabContainer.appendChild(tabButton);

            // Create tab content container
            const tabContent = document.createElement('div');
            tabContent.id = `${key}-content`;
            tabContent.className = 'tab-content hidden';
            tabContent.dataset.loaded = 'true'; // Mark as loaded initially to prevent re-fetching
            tabContent.innerHTML = this.generateList(value);

            this.container.appendChild(tabContent);
        }

        this.container.prepend(tabContainer); // Add tabs at the top
    }

    initTabEvents() {
        this.tabButtons = this.container.querySelectorAll(".tab-button");
        this.tabContents = this.container.querySelectorAll(".tab-content");
        // You can loop through each button and assign event listeners here as well if needed
    }

    handleTabClick(button) {
        const targetTab = button.dataset.tab;
        const targetContent = this.container.querySelector(`#${targetTab}`);

        // Check if the content is already visible
        const isContentVisible = !targetContent.classList.contains("hidden");

        // Hide all tab contents and remove active class from all buttons if content is not visible
        if (!isContentVisible) {
            this.tabContents.forEach(content => content.classList.add("hidden"));
            this.tabButtons.forEach(btn => btn.classList.remove("bg-gray-300", "font-bold", "active"));
        }

        // Toggle the clicked tab button and its content
        if (isContentVisible) {
            button.classList.remove("bg-gray-300", "font-bold", "active");
            targetContent.classList.add("hidden");
        } else {
            button.classList.add("bg-gray-300", "font-bold", "active");
            targetContent.classList.remove("hidden");
        }
    }

    generateList(entries) {
        return `
            <ul class="list-none space-y-4">
                ${entries.map(entry => `<li>${this.generateTabContent(entry)}</li>`).join('')}
            </ul>
        `;
    }

    generateTabContent(entry) {
        const timelineItems = JSON.parse(entry.timeline).map(item => `<li>${item.message}</li>`).join('');
        return `
            <li>
                <p>Status: <b>${entry.status.toUpperCase()}</b></p>
                <p>Created At: ${entry.createdAt}</p>
                <p>Timeline:</p>
                <ul class="list-disc p-40" style="padding-left: 40px;">${timelineItems}</ul>
            </li>
        `;
    }
}
